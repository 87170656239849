<template>
  <div class="account-config">
    <PageTitle title="帳號設定" hideBtn />
    <section>
      <el-form label-position="left" label-width="120px" class="mb-3">
        <div class="form-container">
          <p class="form-title">管理員資訊</p>
          <el-form-item label="身份別">
            <span>{{ role(user.role) || '' }}</span>
          </el-form-item>
          <el-form-item label="姓名">
            <span>{{ user.name || '' }}</span>
          </el-form-item>
          <el-form-item label="電子信箱">
            <span>{{ email || '' }}</span>
          </el-form-item>
        </div>
      </el-form>
    </section>

    <section>
      <el-form label-position="top" class="mb-3">
        <div class="form-container">
          <p class="form-title">修改密碼</p>
          <el-form-item label="新密碼">
            <el-input
              v-model="password"
              class="w-full"
              style="margin-bottom: 10px;"
              placeholder="請輸入新密碼"
              show-password
            />
          </el-form-item>
          <el-form-item>
            <el-button
              type="primary"
              class="ml-auto d-block"
              @click="updateUserPassword"
            >
              確認變更
            </el-button>
          </el-form-item>
        </div>
      </el-form>
      <el-form label-position="left">
        <div class="form-container">
          <el-form-item :label="formLineNotifyConfig.formlabel" class="mb-0">
            <el-button
              :type="formLineNotifyConfig.btnType"
              class="ml-auto d-block"
              @click="lineNotify"
            >
              {{ formLineNotifyConfig.btnlabel }}
            </el-button>
          </el-form-item>
        </div>
      </el-form>
    </section>
  </div>
</template>
<script>
import {
  UpdateMePassword,
  LineNotify,
  FindMeLineNotify,
  FindCustomRole,
  GetMe,
} from '@/api/user.js'
import adminRole from '@/config/admin'
export default {
  name: 'AccountConfig',
  props: {
    show: Boolean,
    width: String,
  },
  data: () => ({
    password: '',
    email: '',
    lineNotifyUsed: false,
    adminRoleList: ['orgAdmin', 'shopManager', 'shopProvider', 'shopMod'],
    customRoleOptions: [],
  }),
  computed: {
    user () {
      return this.$store.getters.user
    },

    formLineNotifyConfig () {
      return this.lineNotifyUsed.use ? {
        formlabel: '重新綁定 LineNotify',
        btnlabel: '重新綁定',
        btnType: 'warning',
      } : {
        formlabel: '綁定 LineNotify',
        btnlabel: '前往綁定',
        btnType: 'primary',
      }
    },
    availableRoleList () {
      const availableRoleList = []
      this.adminRoleList.forEach(item => {
        availableRoleList.push(adminRole[item])
      })
      this.customRoleOptions.map(item => {
        availableRoleList.push({ name: item.name, value: item.role })
      })

      return availableRoleList
    },
  },

  async mounted () {
    await this.getUserCustomRole()
    await this.findMeLineNotify()
    await this.findMeEmail()
  },
  methods: {
    role (role) {
      const targetRole = this.availableRoleList.find(item => item.value === role)

      return targetRole ? targetRole.name : ''
    },

    //= > 更新密碼
    async updateUserPassword () {
      if (this.password === '') {
        this.$message.warning('請輸入新密碼')
        return
      }
      try {
        await UpdateMePassword({
          password: this.password,
        })
        this.password = ''
        this.$message.success('密碼更新成功!')
      } catch (error) {
        console.log(error)
        this.$message.error(error)
      }
    },

    async lineNotify () {
      try {
        const res = await LineNotify({
          redirectURL: window.location.href,
        })

        window.location = res.oauthURL
        // this.$message.success('已成功綁定 LineNotify !')
      } catch (error) {
        this.$message.error({
          message: error || error.message,
        })
      }
    },

    async findMeLineNotify () {
      try {
        this.lineNotifyUsed = await FindMeLineNotify()
      } catch (error) {
        this.$message.error({
          message: error || error.message,
        })
      }
    },

    async findMeEmail() {
      try {
        const { AuthEmail } = await GetMe()
        this.email = AuthEmail.email
      } catch (error) {
        this.$message.error({
          message: error || error.message,
        })
      }
    },

    async getUserCustomRole () {
      try {
        this.customRoleOptions = await FindCustomRole()
      } catch (error) {
        console.log(error)
        this.$message(error)
      }
    },
  },
}
</script>

<style scoped lang="scss">
.account-config {
  .el-form {
    width: 456px;
  }
  .form-container {
    padding: 28px;
    background-color: white;
    border-radius: 4px;

    span {
      @apply text-gray-80;
      font-weight: 400;
    }
  }
}

.form-title {
  margin-bottom: 16px;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
}
</style>
